import React from 'react';
import { Twitter, Facebook, LinkedInIcon } from 'icons';
import { container, socialIcons } from './SocialShares.module.css';

export const SocialShares = ({ liveUrl, slug }) => (
	<div className={container}>
		<div className={socialIcons}>
			<a
				href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.authoritydental.org%2F${slug}&amp;src=sdkpreparse`}
				rel='noopener noreferrer'
				target='_blank'
				aria-label='Facebook'
			>
				<Facebook />
			</a>
			<a
				href={`https://twitter.com/intent/tweet?url=${liveUrl}`}
				rel='noopener noreferrer'
				target='_blank'
				aria-label='Twitter'
			>
				<Twitter />
			</a>
			<a
				href={`https://www.linkedin.com/shareArticle?mini=true&url=${liveUrl}`}
				rel='noopener noreferrer'
				target='_blank'
				aria-label='LinkedIn'
			>
				<LinkedInIcon />
			</a>
		</div>
	</div>
);
