import React, { memo } from 'react';
import { TocList } from './TocList';
import { container, list, title, tocNav } from './Toc.module.css';

export const TocDesktop = memo(({ toc }) => (
	<nav className={tocNav}>
		<div className={`${container} mt-2`}>
			<p className={`${title} no-margin txt-uppercase`}>table of contents</p>
			<ul className={list}>
				<TocList toc={toc} />
			</ul>
		</div>
	</nav>
));

TocDesktop.displayName = 'TocDesktop';
