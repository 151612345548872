import React from 'react';
import { Link } from 'gatsby';
import isPlainObject from 'lodash/isPlainObject';
import { capitalize, dashName } from 'utilities';
import { useCategory } from 'hooks';
import { breadcrumbs, divider, link } from './Breadcrumbs.module.css';

export const Breadcrumbs = ({ category, subcategory }) => {
	let categoryObj;
	let subcategoryObj;

	const categoryById = useCategory(category);
	const subcategoryById = useCategory(subcategory);

	if (categoryById) {
		categoryObj = { name: categoryById.name, slug: categoryById.slug };
	} else if (isPlainObject(category) && 'name' in category && 'slug' in category) {
		categoryObj = category;
	}

	if (subcategoryById) {
		subcategoryObj = { name: subcategoryById.name, slug: subcategoryById.slug };
	} else if (isPlainObject(subcategory) && 'name' in subcategory && 'slug' in subcategory) {
		subcategoryObj = subcategory;
	}

	return (
		<div className='breadcrumbs-container'>
			<div className={`breadcrumbs ${breadcrumbs}`}>
				<>
					<Link to={`/${dashName(categoryObj?.slug)}`} className={link}>
						{capitalize(categoryObj?.name)}
					</Link>
					{!!subcategoryObj ? (
						<>
							<span className={divider}>&#47;</span>
							<Link to={`/${dashName(subcategoryObj.slug)}`} className={link}>
								{capitalize(subcategoryObj.name)}
							</Link>
						</>
					) : null}
				</>
				<div></div>
			</div>
		</div>
	);
};
