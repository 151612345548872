import React, { useContext } from 'react';
import { PostContext } from 'context/postContext';
import { usePostActiveId } from 'hooks';
import { active, item, link } from './Toc.module.css';

export const TocList = ({ toc }) => {
	const { postAnchors } = useContext(PostContext);
	const activeId = usePostActiveId(postAnchors);
	const setActiveClass = (id) => (id === activeId ? active : '');

	if (!toc.length) return null;

	return toc.map((el, idx) => (
		<li key={`${el.id}-${idx}`} className={`${item} ${setActiveClass(el.id)}`}>
			<a className={link} href={`#${el.id}`}>
				{el.title}
			</a>
		</li>
	));
};
