import React from 'react';
import { Link, navigate } from 'gatsby';
import { authorLinks, link } from './Headings.module.css';

export const AuthorInfo = ({ first_name, last_name, suffix, url, linkedin }) => {
	const suf = !!suffix ? ` ${suffix}` : '';

	return (
		<>
			<Link to={`/${url}`} className={link}>
				{`${first_name} ${last_name}${suf}`}
			</Link>
			<div className={authorLinks}>
				<button onClick={() => navigate(`/${url}`)} className={`${link} underline mr-1`}>
					Bio
				</button>
				{linkedin && (
					<a href={linkedin} className={`${link} underline`}>
						LinkedIn
					</a>
				)}
			</div>
		</>
	);
};
