import React, { useContext, useEffect } from 'react';
import { getSrc } from 'gatsby-plugin-image';
import { sbEditable } from '@storyblok/storyblok-editable';
import { render, NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer';
import isPlainObject from 'lodash/isPlainObject';
import { useContentFunctor, useMetaDataQuery, useStoryblok } from 'hooks';
import { articleBreadcrumbsSchema, faqSchema, flickrSchema } from 'utilities';
import { PostContext } from 'context/postContext';
import { NavigationContext } from 'context/navigationContext';
import { Layout, PostFooter, Seo } from 'containers';
import { AdUnit } from 'components/AdUnit/AdUnit';
import { PostHeading } from 'components/Headings/PostHeading';
import { TocDesktop } from 'components/TocDesktop/TocDesktop';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Description } from 'components/Description/Description';
import { DynamicComponent } from 'components/DynamicComponent';
import { Takeaways } from 'components/Takeaways/Takeaways';
import { SocialShares } from 'components/SocialShares/SocialShares';
import { TocMobile } from 'components/TocMobile/TocMobile';

const Post = ({ pageContext, location }) => {
	const mainNavigation = pageContext.mainNavigation.items;
	const { siteUrl } = useMetaDataQuery();
	const { setNavItems } = useContext(NavigationContext);
	const story = useStoryblok(pageContext.story, location);
	const contentBody = useContentFunctor(story?.content.body);
	const { setPostAnchors } = useContext(PostContext);
	const featuredImageUrl = `${siteUrl}${getSrc(story.content.featured_image.node)}`;
	const articleTitle = story?.content?.article_title;
	const mandatoryTakeaways = [
		{ item: story?.content?.takeaway_1, _uid: '667dbe7a-900c-4182-bb37-13222680bfda' },
		{ item: story?.content?.takeaway_2, _uid: '47b86fb4-64fc-4089-ac54-e87acd3b913b' },
		{ item: story?.content?.takeaway_3, _uid: 'b8597aaf-e32a-4342-88a2-8acae07dce5f' },
	];
	const optionalTakeaway = [{ item: story?.content?.takeaway_4, _uid: 'eb4f0a3b-b06f-427a-a57f-63814681889e' }];
	const articleTakeaways = {
		takeaway: [...mandatoryTakeaways, ...(!!story?.content?.takeaway_4 ? optionalTakeaway : [])],
	};

	const metaTitle = `${story?.content?.article_title} | Authority Dental`;
	const articleLead = story?.content?.article_lead;

	const parsedArticleLead = articleLead
		? render(articleLead, {
				nodeResolvers: {
					[NODE_PARAGRAPH]: (children) => children,
				},
		})
		: null;

	useEffect(() => {
		if (contentBody?.anchors.length) setPostAnchors(contentBody.anchors);
		setNavItems(mainNavigation);
	}, []);

	const { published_at, created_at, meta_created_at, meta_published_at, slug } = story;
	const { faq, heading2s, reference } = contentBody;
	const articleClass = 'with-toc';
	const liveUrl = `${siteUrl}/${slug}`;
	const flickrObjs = flickrSchema(pageContext.story, siteUrl, contentBody?.flickr);

	const schema = {
		url: liveUrl,
		image: featuredImageUrl,
		headline: articleTitle,
		title: metaTitle,
		description: parsedArticleLead?.[0][0],
		published: meta_created_at,
		modified: meta_published_at,
		authors: story.content.authors.map((el) =>
			isPlainObject(el) && 'content' in el ? JSON.parse(el.content) : el
		),
		reference: reference,
	};

	const components = story.content.body?.map((blok) => {
		return <DynamicComponent blok={blok} key={blok._uid} />;
	});

	return (
		<Layout type='post'>
			<Seo
				title={metaTitle}
				description={parsedArticleLead?.[0][0]}
				canonical={liveUrl}
				url={liveUrl}
				image={featuredImageUrl}
				published={meta_created_at}
				modified={meta_published_at}
				isPost={true}
				schema={schema}
				faq={faqSchema(faq)}
				flickr={flickrObjs}
				breadcrumbs={articleBreadcrumbsSchema(
					story.content.category,
					story.content.subcategory,
					articleTitle,
					slug
				)}
			/>
			<article className={articleClass}>
				<Breadcrumbs category={story.content.category} subcategory={story.content.subcategory} />
				{articleTitle && <PostHeading articleTitle={articleTitle} authorsInfo={story?.content?.authors} />}
				<main className='post-container'>
					<div className='post-content' {...sbEditable(story.content)}>
						{articleTakeaways && <Takeaways blok={articleTakeaways} />}
						{articleLead && <Description blok={{ editor: articleLead }} />}
						<AdUnit />
						<TocMobile toc={heading2s} />
						{components}
					</div>
					<TocDesktop toc={heading2s} />
				</main>
				<aside>
				</aside>
				<PostFooter published_at={created_at} updated_at={published_at} />
				<SocialShares liveUrl={liveUrl} slug={story.slug} />
			</article>
		</Layout>
	);
};

export default Post;
