import React from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import isPlainObject from 'lodash/isPlainObject';
import { Description } from 'components/Description/Description';
import { takeaways } from './Takeaways.module.css';

export const Takeaways = ({ blok: { takeaway } }) => (
	<ul className={takeaways}>
		{takeaway.map(({ item, _uid }) => {
			if (!render(item)) return;

			if (isPlainObject(item) && 'content' in item) {
				return (
					<li key={_uid}>
						<Description blok={{ editor: item }} />
					</li>
				);
			}

			return <li key={_uid}>{item}</li>;
		})}
	</ul>
);
