import React from 'react';
import { ChevronRightIcon } from 'icons';
import { item, link } from './Toc.module.css';

export const TocList = ({ toc }) => {
	if (!toc.length) return null;

	return toc.map((el, idx) => (
		<li key={`${el.id}-${idx}`} className={item}>
			<a className={link} href={`#${el.id}`}>
				<span>{el.title}</span>
				<ChevronRightIcon fill='#fff' />
			</a>
		</li>
	));
};
