import React, { memo } from 'react';
import { navigate } from 'gatsby';
import isPlainObject from 'lodash/isPlainObject';
import { arePropsEqual } from 'utilities';
import { useAuthors } from 'hooks';
import { Heading } from './Heading';
import { AuthorAvatar } from 'components/AuthorAvatar/AuthorAvatar';
import { AuthorInfo } from './AuthorInfo';
import { authors, authorInfoBox, avatar, description, link } from './Headings.module.css';

const AuthorsContainer = ({ children, classes }) => <div className={`${authors} ${classes}`}>{children}</div>;

export const PostHeading = memo(({ articleTitle, authorsInfo }) => {
	const authorsById = useAuthors(authorsInfo);
	let authorsContent = null;
	const title = articleTitle ? { title: articleTitle } : null;

	if (authorsById) {
		authorsContent = (
			<>
				<div className={authorInfoBox}>
					{authorsById.length ? (
						<img
							src={authorsById[0].photo.filename}
							className={avatar}
							alt={`${authorsById[0].first_name} ${authorsById[0].last_name}`}
						/>
					) : null}
					<div className={description}>
						<AuthorInfo {...authorsById[0]} />
					</div>
				</div>
				{!!authorsById[1] && (
					<div className={authorInfoBox}>
						{authorsById.length ? (
							<img
								src={authorsById[1].photo.filename}
								className={avatar}
								alt={`${authorsById[1].first_name} ${authorsById[1].last_name}`}
							/>
						) : null}
						<div className={description}>
							<AuthorInfo {...authorsById[1]} />
						</div>
					</div>
				)}
			</>
		);
	} else if (authorsInfo.some((el) => isPlainObject(el) && 'node' in el)) {
		const authorStories = authorsInfo.map((el) => JSON.parse(el.content));

		if (authorStories.length) {
			authorsContent = (
				<>
					<div className={authorInfoBox}>
						{authorStories.length ? (
							<button onClick={() => navigate(`/${authorStories[0].url}`)} className={link}>
								<AuthorAvatar {...authorStories[0]} navigable />
							</button>
						) : null}
						<div className={description}>
							<AuthorInfo {...authorStories[0]} />
						</div>
					</div>
					{!!authorStories[1] && (
						<div className={authorInfoBox}>
							{authorStories.length ? (
								<button onClick={() => navigate(`/${authorStories[1].url}`)} className={link}>
									<AuthorAvatar {...authorStories[1]} navigable />
								</button>
							) : null}
							<div className={description}>
								<AuthorInfo {...authorStories[1]} />
							</div>
						</div>
					)}
				</>
			);
		}
	}

	return (
		<header className='post-header'>
			<div className='post-header-content'>
				<Heading blok={title} />
				<AuthorsContainer>{authorsContent}</AuthorsContainer>
			</div>
		</header>
	);
}, arePropsEqual);

PostHeading.displayName = 'PostHeading';
