import React from 'react';
import { TocList } from './TocList';
import { list, title, tocNav } from './Toc.module.css';

export const TocMobile = ({ toc }) => (
	<nav className={`${tocNav} mt-3 mb-3`}>
		<div className="mt-2">
			<p className={`${title} no-margin`}>TABLE OF CONTENTS</p>
			<ol className={list}>
				<TocList toc={toc} />
			</ol>
		</div>
	</nav>
);
